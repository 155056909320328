<template>
    <div class="c-chat">
        <div ref="chatBody" class="c-chat__body">
            <CChatMessages ref="messagesList" class="c-chat__messages" :messages="messages" :user="user"
                           :actions-pending="actionsPending" :loading-data="loadingMessagesData" :tags="tags"
                           @edit="editMessage" @delete="deleteMessage"/>
        </div>
        <CChatFooter v-model="myMessage" :sending-message="sendingMessage" :editing-message="editingMessage"
                     :tags="tags" class="c-chat__footer" @sendMessage="sendMessage" @closeEditing="closeEditing"/>
    </div>
</template>

<script>
import CChatMessages from '@/components/CChat/CChatMessages'
import CChatFooter from '@/components/CChat/CChatFooter'
import {mapActions, mapGetters} from 'vuex'
import debounce from 'lodash/debounce'

export default {
    name: 'CChat',
    components: {CChatFooter, CChatMessages},
    props: {
        messages: {
            type: Array,
            default: () => [],
        },
        loadingMessagesData: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            myMessage: '',

            sendingMessage: false,
            actionsPending: false,

            editingMessage: null,

            staticData: Object.seal({
                messagesObserver: null,
            }),
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            chatsUserTags: 'chatsUserTags'
        }),

        trimmedMessage() {
            return this.myMessage.trim()
        },

        tags() {
            const tags = this.chatsUserTags
            const config = this.config

            if (tags) {
                if (tags.length && Array.isArray(tags)) {
                    return tags
                }
                if (config.part && tags[config.part]) {
                    return tags[config.part]
                }
            }
            return []
        }
    },
    watch: {
        messages: {
            immediate: true,
            async handler(newMessages, oldMessages) {
                if (!newMessages.length || newMessages?.length === oldMessages?.length)
                    return

                await this.$nextTick()

                setTimeout(this.initMessagesObserver)
            },
        },
    },
    mounted() {
        if (this.messages.length) {
            this.scrollToFirstUnreadMessage()
        }
    },
    methods: {
        ...mapActions({
            handleDeleteMessage: 'handleDeleteMessage',
            handleEditMessage: 'handleEditMessage',
            handleSendMessage: 'handleSendMessage',
            handleSetAsMarked: 'handleSetAsMarked',
        }),

        initMessagesObserver() {
            if (!this.$refs.messagesList) return

            if (this.staticData.messagesObserver) {
                this.destroyMessagesObserver()
            }

            this.staticData.messagesObserver = new IntersectionObserver(
                (messages) => {
                    messages.some((entry) => {
                        if (!entry.isIntersecting) return

                        const {target} = entry
                        const {id} = target.dataset

                        const messageData = this.messages.find(
                            ({id: messageId}) => messageId === +id,
                        )
                        const readMessage = messageData.marked_user_ids.includes(
                            this.user.id,
                        )

                        if (readMessage) {
                            this.staticData.messagesObserver.unobserve(target)
                        } else {
                            const channelSuffix = messageData?.channel_suffix
                            this.markAsRead(id, channelSuffix)
                        }
                    })
                },
                {root: this.$refs.chatBody},
            )

            this.messages.forEach((message, index) => {
                const messageEl = this.$refs.messagesList.$refs.messages[index]
                this.staticData.messagesObserver.observe(messageEl)
            })
        },

        destroyMessagesObserver() {
            this.staticData.messagesObserver.disconnect()
            this.staticData.messagesObserver = null
        },

        editMessage(message) {
            this.editingMessage = message
            this.myMessage = message.content
        },
        closeEditing() {
            this.editingMessage = null
            this.myMessage = ''
        },
        deleteMessage(message) {
            this.actionsPending = true

            if (this.editingMessage?.content) {
                this.closeEditing()
            }

            this.handleDeleteMessage(message)
                .catch(() => {
                    this.toast('warning', 'Error, please try again later')
                })
                .finally(() => (this.actionsPending = false))
        },
        sendMessage(tags) {
            if (!this.trimmedMessage.length) return

            this.sendingMessage = true

            let request
            if (this.editingMessage?.content) {
                request = this.handleEditMessage({
                    message_id: this.editingMessage.id,
                    content: this.trimmedMessage,
                })
            } else {
                request = this.handleSendMessage({
                    task_id: this.config.taskId,
                    scope_id: this.config.scopeId,
                    content: this.trimmedMessage,
                    part: this.config.part,
                    tagged_users: tags
                })
            }

            request
                .then(() => {
                    this.myMessage = ''
                    this.closeEditing()

                    this.scrollToLastMessage()
                })
                .finally(() => {
                    this.sendingMessage = false
                })
        },
        markAsRead: debounce(function (messageId, channelSuffix) {
            this.handleSetAsMarked({
                messageId,
                userId: this.user.id,
                channelSuffix,
            })
        }, 500),

        scrollToLastMessage() {
            const {chatBody} = this.$refs
            chatBody.scrollTop = chatBody.scrollHeight
        },
        scrollToFirstUnreadMessage() {
            const {chatBody} = this.$refs
            const {messagesList} = this.$refs

            const lastUnreadElement = messagesList.$refs.messages.find(
                (el) => el.dataset.unread === 'true',
            )

            if (chatBody) {
                if (lastUnreadElement) {
                    chatBody.scrollTop = Math.abs(
                        chatBody.getBoundingClientRect().height -
                        (lastUnreadElement.getBoundingClientRect().top -
                            lastUnreadElement.getBoundingClientRect().height),
                    )
                } else {
                    this.scrollToLastMessage()
                }
            }
        },
    },
}
</script>

<style lang="scss">
.c-chat {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__body {
        overflow: auto;
        flex: 1 1 100%;
    }

    &__footer {
        flex: 1 0 auto;
    }
}
</style>
